import {
  NotFieldTemplate,
  ObjectFieldTemplate,
} from "../JsonSchemaForm/FieldTemplate";

const spec = {
  name: "零售大量訂購",
  schema: {
    type: "object",
    required: ["product"],
    properties: {
      product: {
        title: "詢價商品",
        type: "string",
        enum: [],
      },
      quantity: {
        title: "製作數量",
        type: "number",
        default: 1,
        unit: "份",
      },
      budget: {
        title: "製作預算",
        type: "string",
        minLength: 1,
      },
      expect_time: {
        title: "預計收到成品時間",
        type: "string",
      },
      note: {
        title: "製作備註",
        type: "string",
      },
    },
  },
  uiSchema: {
    "ui:FieldTemplate": NotFieldTemplate,
    "ui:options": {
      label: false,
    },
    product: {
      "ui:placeholder": "選擇零售商品",
    },
    quantity: {
      "ui:help":
        "請提供製作「總數」，若有不同款式需有個別指定數量，請於「製作備註」另外說明。",
    },
    budget: {
      "ui:placeholder": "請輸入您的預計製作預算",
      "ui:help":
        "若為單件預算請寫「單件預算 / 單位」；若為總預算請寫「總預算：」。",
    },
    expect_time: {
      "ui:widget": "date",
      "ui:help":
        "建議若為指定時間需使用，如活動、展覽等，請於備註另外說明活動舉辦時間。請注意！實際交期時間將依正式製作需求評估後確認！",
    },
    note: {
      "ui:placeholder":
        "若上列項目選單未能讓您詳盡說明製作需求，如：特殊材質選擇／書籍類特殊插頁需求／加工方式 / 款式等，皆請於該欄位填寫備註。",
      "ui:widget": "textarea",
      "ui:options": {
        rows: 3,
      },
    },
  },
};

export default spec;

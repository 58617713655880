import {
  NotFieldTemplate,
  ObjectFieldTemplate,
} from "../JsonSchemaForm/FieldTemplate";

const spec = {
  name: "其他品項",
  schema: {
    type: "object",
    required: ["name"],
    properties: {
      name: {
        title: "製作品名",
        type: "string",
        minLength: 1,
      },
      product: {
        title: "製作項目",
        type: "object",
        properties: {
          name: {
            title: "名稱",
            type: "string",
            enum: [
              "L型透明夾",
              "U型透明夾",
              "馬克杯",
              "直式帆布袋",
              "橫式帆布袋",
              "紙膠帶 (寬1.5cmX長5m)",
              "紙膠帶 (寬1.5cmX長10m)",
              "紙膠帶 (寬2cmX長5m)",
              "紙膠帶 (寬2cmX長10m)",
              "識別證",
              "桌曆 / 三角桌曆",
              "玻璃杯",
              "鑰匙圈",
              "壓克力立牌",
              "胸章/徽章",
              "抱枕",
              "書籤",
              "杯墊",
              "口罩",
              "拼圖",
              "刮刮卡",
              "木板雷雕",
              "掛布",
              "無框畫",
              "帆布輸出 (高解析度)",
              "大圖輸出 (PP海報＋亮膜)",
              "大圖輸出 (背膠PVC海報＋亮膜)",
              "大圖輸出裱貼合成板 (亮膜)",
              "大圖輸出 (PP海報＋霧膜)",
              "大圖輸出 (背膠PVC海報＋霧膜)",
              "大圖輸出裱貼裱合成板 (霧膜)",
              "單透布",
              "雙透布",
              "羅馬旗",
              "其他項目 (自行填寫)",
            ],
            default: "L型透明夾",
          },
        },
        dependencies: {
          name: {
            oneOf: [
              {
                properties: {
                  name: {
                    enum: ["其他項目 (自行填寫)"],
                  },
                  note: {
                    title: "其他項目",
                    type: "string",
                  },
                },
              },
              {
                properties: {
                  name: {
                    enum: [
                      "L型透明夾",
                      "U型透明夾",
                      "馬克杯",
                      "直式帆布袋",
                      "橫式帆布袋",
                      "紙膠帶 (寬1.5cmX長5m)",
                      "紙膠帶 (寬1.5cmX長10m)",
                      "紙膠帶 (寬2cmX長5m)",
                      "紙膠帶 (寬2cmX長10m)",
                      "識別證",
                      "桌曆 / 三角桌曆",
                      "玻璃杯",
                      "鑰匙圈",
                      "壓克力立牌",
                      "胸章/徽章",
                      "抱枕",
                      "書籤",
                      "杯墊",
                      "口罩",
                      "拼圖",
                      "刮刮卡",
                      "木板雷雕",
                      "掛布",
                      "無框畫",
                      "帆布輸出 (高解析度)",
                      "大圖輸出 (PP海報＋亮膜)",
                      "大圖輸出 (背膠PVC海報＋亮膜)",
                      "大圖輸出裱貼合成板 (亮膜)",
                      "大圖輸出 (PP海報＋霧膜)",
                      "大圖輸出 (背膠PVC海報＋霧膜)",
                      "大圖輸出裱貼裱合成板 (霧膜)",
                      "單透布",
                      "雙透布",
                      "羅馬旗",
                    ],
                  },
                },
              },
            ],
          },
        },
      },
      size: {
        title: "成品尺寸",
        type: "object",
        properties: {
          type: {
            title: "尺寸",
            type: "string",
            enum: ["自訂尺寸"],
            default: "自訂尺寸",
          },
        },
        dependencies: {
          type: {
            oneOf: [
              {
                properties: {
                  type: {
                    enum: ["自訂尺寸"],
                  },
                  detail: {
                    title: "自訂尺寸",
                    type: "object",
                    properties: {
                      length: {
                        title: "長",
                        type: "integer",
                        default: 0,
                        unit: "mm",
                      },
                      width: {
                        title: "寬",
                        type: "integer",
                        default: 0,
                        unit: "mm",
                      },
                      height: {
                        title: "高",
                        type: "integer",
                        default: 0,
                        unit: "mm",
                      },
                    },
                  },
                },
              },
            ],
          },
        },
      },
      attachment: {
        title: "上傳檔案",
        type: "string",
      },
      quantity: {
        title: "製作數量",
        type: "number",
        default: 1,
        unit: "份",
      },
      budget: {
        title: "製作預算",
        type: "string",
        minLength: 1,
      },
      expect_time: {
        title: "預計收到成品時間",
        type: "string",
      },
      note: {
        title: "製作備註",
        type: "string",
      },
      // quote_way: {
      //   title: "報價方式",
      //   type: "string",
      //   enum: ["想先簡單了解報價", "需索取正式報價單"],
      // },
    },
  },
  uiSchema: {
    "ui:FieldTemplate": NotFieldTemplate,
    "ui:options": {
      label: false,
    },
    size: {
      "ui:ObjectFieldTemplate": ObjectFieldTemplate,
      type: {
        "ui:FieldTemplate": NotFieldTemplate,
      },
      detail: {
        "ui:FieldTemplate": NotFieldTemplate,
        "ui:field": "SizeField",
      },
      name: {
        "ui:FieldTemplate": NotFieldTemplate,
      },
    },
    name: {
      "ui:placeholder": "名稱",
    },
    product: {
      "ui:ObjectFieldTemplate": ObjectFieldTemplate,
      "ui:placeholder": "選擇製作項目",
      name: {
        "ui:FieldTemplate": NotFieldTemplate,
      },
      note: {
        "ui:FieldTemplate": NotFieldTemplate,
      },
    },
    note: {
      "ui:placeholder":
        "若上列項目選單未能讓您詳盡說明製作需求，如：特殊材質選擇／書籍類特殊插頁需求／加工方式 / 款式等，皆請於該欄位填寫備註。",
      "ui:widget": "textarea",
      "ui:options": {
        rows: 3,
      },
    },
    quantity: {
      "ui:help":
        "請提供製作「總數」，若有不同款式需有個別指定數量，請於「製作備註」另外說明。",
    },
    expect_time: {
      "ui:widget": "date",
      "ui:help":
        "建議若為指定時間需使用，如活動、展覽等，請於備註另外說明活動舉辦時間。請注意！實際交期時間將依正式製作需求評估後確認！",
    },
    attachment: {
      "ui:widget": "file",
    },
    quote_way: {
      "ui:widget": "radio",
    },
    budget: {
      "ui:placeholder": "請輸入您的預計製作預算",
      "ui:help":
        "若為單件預算請寫「單件預算 / 單位」；若為總預算請寫「總預算：」。",
    },
  },
};

export default spec;
